import React from 'react';
import {missionIcon, valuesIcon, visionIcon} from '../../../../assets/svg-icons';
// import {one} from '../../../../assets/home/mission-and-vision/one.js';
// import {two} from '../../../../assets/home/mission-and-vision/two.js';
// import {three} from '../../../../assets/home/mission-and-vision/three.js';
// import atob from 'atob';

import './styles.scss';
const OurStory = () => (
  <div className="our-story-section">
    <div className="our-story-info">
      <span className="bg-blue">MISSION & VISION</span>
      <h4>We innovate to be your success partner</h4>
      <p>
        Hexact's vision and mission guide our current and future decisions, and we appreciate all our company stands for
        and aspires to accomplish every single day.
      </p>
    </div>
    <div>
      <div className="d-flex info-section">
        <div className="img-section only-desktop">
          <img src='https://hexact.io/home/mission.png' alt="hexact-info-icon" />
        </div>
        <div className="text-section">
          <span>{missionIcon}</span>
          <h5 className="m-0">Hexact Mission</h5>
          <div className="img-section only-mobile">
            <img src='https://hexact.io/home/mission.png' alt="hexact-info-icon" />
          </div>
          <p>
            Our mission is to empower you with the true power of the cloud + AI to delegate and scale work, so you can
            change the world.
          </p>
        </div>
      </div>

      <div className="d-flex info-section">
        <div className="text-section">
          <span>{visionIcon}</span>
          <h5 className="m-0">Hexact Vision</h5>
          <div className="img-section only-mobile">
            <img src='https://hexact.io/home/vision.png' alt="hexact-info-icon" />
          </div>
          <p>Hexact is inventing the OS for the cloud-powered world of tomorrow.</p>
        </div>
        <div className="img-section only-desktop">
          <img src='https://hexact.io/home/vision.png' alt="hexact-info-icon" />
        </div>
      </div>

      <div className="d-flex info-section">
        <div className="img-section only-desktop">
          <img src='https://hexact.io/home/purpose.png' alt="hexact-info-icon" />
        </div>
        <div className="text-section">
          <span>{valuesIcon}</span>
          <h5 className="m-0">Hexact Purpose</h5>
          <div className="img-section only-mobile">
            <img src='https://hexact.io/home/purpose.png' alt="hexact-info-icon" />
          </div>
          <p>
            We are the equalizer. Our movement makes automation, AI, and growth universally accessible - from
            solopreneurs to SMEs to Fortune 500 companies.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default OurStory;
