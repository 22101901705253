import {Link, navigate} from 'gatsby';
import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {hexomaticIcon} from '../../../../assets/svg-icons';
import uiPath from '../../../../assets/home/icons/2.svg';
import zapier from '../../../../assets/home/icons/4.svg';
import anywhere from '../../../../assets/home/icons/5.png';
import bluePrism from '../../../../assets/home/icons/3.svg';
import workFusion from '../../../../assets/home/icons/1.svg';

import './styles.scss';
import '../../../../pages/products/styles.scss';

const Theme = () => (
  <div className="theme-sections">
    <div>
      <div className="our-products-info">
        <span className="bg-blue cursor-pointer" onClick={() => navigate('/products')}>OUR PRODUCTS</span>
        <h4>Business Intelligence Solutions</h4>
        <p style={{color: '#718096'}}>Empowered by artificial intelligence, data mining, and machine learning.</p>
        <Link to="/products">
          Learn more <span>&#62;</span>
        </Link>
      </div>
      <div className="products-section">
      <Row className="products-section-1">
        <Col lg={6} className="d-none d-lg-flex px-3">
          <img src="https://hexact.io/products/hexomatic.png" style={{borderRadius: '10px'}} alt="Hexomatic" />
        </Col>
        <Col lg={6} className="content-container px-3">
          <h2 className="page-title">
            {hexomaticIcon}{' '}
            <a href="https://hexomatic.com" target="_blank" style={{color: '#fff'}}>
              Hexomatic
            </a>
          </h2>
          <img
            src="https://hexact.io/products/hexomatic.png"
            alt="Hexomatic"
            className="d-lg-none my-4"
            style={{borderRadius: '10px'}}
          />
          <p style={{color: '#718096'}}>
            Hexomatic is your no-code growth hacking sidekick, enabling universal access to the internet as your own
            data source, leveraging the most sophisticated AI services to automate and delegate time-consuming tasks.
          </p>
          <p style={{color: '#718096'}}>
            Create your own bots in minutes to extract information from any data source and leverage ready-made
            automations to scale.
          </p>

          <a href="https://hexomatic.com" target="_blank" className="explore-btn">
            Explore Hexomatic
          </a>
        </Col>
      </Row>
      </div>
      {/* <div className="our-products">
        <div className="left-side"></div>
        <div className="products-card">
          <Product
            name="Hexomatic"
            text="Hexomatic is a no-code, data extraction and work automation platform."
            link="https://hexomatic.com"
            className="prod-3"
          />
          <Product
            name="Hexowatch"
            text="Hexowatch is a virtual recon drone, monitoring any page or website for changes."
            link="https://hexowatch.com"
            className="prod-2"
          />
          <Product
            name="Hexospark"
            text="Hexospark is an all-in-one email and CRM platform designed to spark productivity."
            link="https://hexospark.com"
            className="prod-4"
          />
          <Product
            name="Hexometer"
            text="Hexometer provides 24/7 website monitoring to catch problems before they affect your business."
            link="https://hexometer.com"
            className="prod-4"
          />
          <Product
            name="Hexofy"
            text="Hexofy captures data from any page, like magic."
            link="https://hexofy.com"
            className="prod-5"
          />
        </div>
      </div> */}
    </div>
    <div className="theme-footer d-none">
      <div className="img-wrap">
        <img src={workFusion} alt="integration-icon" />
      </div>
      <div className="img-wrap">
        <img src={uiPath} alt="integration-icon" />
      </div>
      <div className="img-wrap">
        <img src={bluePrism} alt="integration-icon" />
      </div>
      <div className="img-wrap">
        <img src={zapier} alt="integration-icon" />
      </div>
      <div className="img-wrap">
        <img src={anywhere} alt="integration-icon" />
      </div>
    </div>
  </div>
);
export default Theme;
